export default
`To calculate the Operational profit margin, first we need to describe what is *EBIT*.

##### Earnings before interest and TAX (EBIT)
Is a measure of a firm's profitability that excludes interest and income tax expenses. 

**EBIT** = Turnover + Other Income + Subsidies – Energy costs – Wages and salaries - Imputed value of unpaid labour - Livestock costs – Feed costs – Repair and maintenance – Other Operational costs – Depreciation of capital

<br>

##### Operational profit margin

Is a measure of the economic performance of a sector or enterprise expressed in relative terms.
It is a difference between total income and all incurred costs (operating, capital and financial). Expressed in percentage. 

$$
OPM = \\frac {EBIT} {TotIn} \\cdot 100
$$

- $OPM$ is **Operational Pprofit margin**
- $TotIn$ is **Total income**
`;